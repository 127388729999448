import React from 'react';
import footerLogo from '../images/footerLogo.png';
import facebook from '../images/facebook.png';
import insta from '../images/insta.png';
import youtube from '../images/youtube.png';
import location from '../images/location-pin-4.png';
import phone from '../images/Outline.png';
import email from '../images/email-inbox.png';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
} from 'reactstrap';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        
        }
     

    render() {
        return (
            <div>
                      <div className="footer">
                    <Container>
                        <Row>
                            <Col xl={6} lg={6} md={4} sm = {12} xs = {12}>
                                <img src={footerLogo} className="img-fluid logo"></img>

                            </Col>

                            <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                                <h6>{this.props.translate('NAVIGATION')}</h6>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <NavItem>
                                            <Link to={`/?lang=${this.props.lang}`} className="nav-link">{this.props.translate('Startseite')}</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to={this.props.products.length ? `/products/${this.props.products[0]._id}/${this.props.products[0].Alias}?lang=${this.props.lang}` : `/?lang=${this.props.lang}`} className="nav-link">{this.props.translate('Produktion')}</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to={`/galerija?lang=${this.props.lang}`} className="nav-link">{this.props.translate('Referenz')}</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to={`/novosti?lang=${this.props.lang}`} className="nav-link">{this.props.translate('Nachrichten')}</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to={`/?lang=${this.props.lang}`} className="nav-link">{this.props.translate('Kontakt')}</Link>
                                        </NavItem>
                                    </Col>

                                </Row>
                            </Col>
                            <Col xl={3} lg={4} md={4} xs = {6} className="offset-xl-1">
                            <div className = "footerContact">
                                <h6>{this.props.translate('KONTAKT')}</h6>
                                <NavItem>
                                    <NavLink href="#"><img src = {location} className = "location"></img>25. Novembar br. 8</NavLink>
                                    <NavLink href = "#" className = "linkSpacer">75 270 Živinice</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#"><img src = {phone} className = "phone"></img>+ 387 35 773 816</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#"><img src = {email} className = "contactIcons"></img>info@eurobau.ba</NavLink>
                                </NavItem>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                    <div className="copyright">
                        <Container>
                            <Row className = "d-block">
                                <Col xl="5" lg="6" md="8" sm="12" xs="12">
                                    <p>Copyright © EuroBau d.o.o. - 2019. All Rights Reserved.</p>
                                </Col>
                            </Row>
                            <Row className = "d-flex justify-content-between">
                            <Col xl="3" lg="3" md="4" sm="12" xs="12" className="justify-content-xl-start justify-content-sm-start">
                                    <p>Created by <span><a href="https://www.novamedia.agency/">NOVA media</a></span></p>
                                </Col>
                                <Col xl = "2" lg = "2">
                                    <img src = {facebook} className = "img-fluid social"></img>
                                    <img src = {insta} className = "img-fluid social"></img>
                                    <img src = {youtube} className = "img-fluid social"></img>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}
