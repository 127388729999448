import React, { Component } from 'react';
import picture1 from '../images/Production1.png';
import picture2 from '../images/Production2.png';
import picture3 from '../images/Production3.png';
import picture4 from '../images/Production1.png';
import picture5 from '../images/Production2.png';
import picture6 from '../images/Production3.png';

import {
    Container,
    Row,
    Col,
    Image,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';

const pictures = [
    {
        src: picture1,
        altText: 'Picture1',
        caption: 'Picture1'
    },
    {
        src: picture2,
        altText: 'Picture2',
        caption: 'Picture2'
    },
    {
        src: picture3,
        altText: 'Picture3',
        caption: 'Picture3'
    }, 
    {
        src: picture4,
        altText: 'Picture3',
        caption: 'Picture3'
    }, 
    {
        src: picture5,
        altText: 'Picture3',
        caption: 'Picture3'
    }, 
    {
        src: picture6,
        altText: 'Picture3',
        caption: 'Picture3'
    }
];

export default class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            activeIndex: 0 
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
      }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onExiting() {
        this.animating = true;
      }
    
      onExited() {
        this.animating = false;
      }
    
      next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
      }
    
      previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
      }
    
      goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
      }
    

    render() {
        const activeIndex = this.state.activeIndex;
        const slides = this.props.items.map((item) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item}
                >
                    <img src={item} className="img-fluid" />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row d-flex justify-content-start">
                                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                                </div>
                            </div>
                        </div>
                    </div>
                </CarouselItem>
            )
        });


        return (
            <div className="gallery-wrapper">
                <Row>
                    {
                        this.props.items.map((item, idx) => {
                            return (
                                <Col xl={4} lg={4} md={6} sm={12} key={idx}>
                                <img src={item} className="img-fluid pic" onClick={() => this.setState({ opened: true, activeIndex: idx })} />
                            </Col>
        
                            )
                        })
                    }
                    {this.state.opened ?
                        <div className="lightbox">
                            <Container>
                                <Row>
                                    <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                    <Carousel className="bg"
                                        activeIndex={activeIndex}
                                        next={this.next}
                                        previous={this.previous}
                                    >
                                        <CarouselIndicators items={this.props.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                        {slides}
                                        <i class="fa fa-angle-left" onClick={this.previous}></i>
                                        <i class="fa fa-angle-right" onClick={this.next}></i>
                                    </Carousel>
                                </Row>
                            </Container>
                        </div> : null
                    }
                </Row>
            </div>
        )
    }
};

