import React, { Component } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import carousel1 from '../images/carouselBg1.png';
import carousel2 from '../images/carouselBg2.png';
import carousel3 from '../images/carouselBg3.png';
import arrowRight from '../images/arrowRight.png';
import arrowLeft from '../images/lijevo.png';
import fb from '../images/fb.png';
import instagram from '../images/instagram.png';
import production1 from '../images/Production1.png';
import production2 from '../images/Production2.png';
import production3 from '../images/Production3.png';
import production4 from '../images/Production4.png';
import production5 from '../images/Production5.png';
import partner from '../images/partner.png'
import mreza from '../images/mreza.png';
import play from '../images/play.png';
import Gallery from '../containers/gallery.js';
import Map from '../containers/map';
import aboutPicture from '../images/aboutPicture.png';
import Navigacija from '../containers/navbar.js';
import Footer from '../containers/footer.js';
import Contact from '../containers/contact.js';
import News from '../containers/news.js';
import {
    Collapse,
    Container,
    Row,
    Col,
    Button,
    Carousel,
    CarouselItem,
    CarouselIndicators,
    CarouselControl,
    Navbar,
    NavbarBrand,
    Form, FormGroup,
    Label, Input,
    DropdownToggle,
    DropdownMenu,
    NavItem,
    NavLink,
    Nav, UncontrolledDropdown
} from 'reactstrap';

const items = [
    {
        src: carousel1,
        altText: 'Slide 1',
        caption: 'Slide 1'
    },
    {
        src: carousel2,
        altText: 'Slide 2',
        caption: 'Slide 2'
    },
    {
        src: carousel3,
        altText: 'Slide3',
        caption: 'Slide 3'
    }

];

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            activeIndex: 0,
            sections: {},
            slides: [],
        }


    }
    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.core.fetchSections((data) => {
            this.setState({ sections: data })
            console.log(data);
        })

        this.props.core.fetchSlides((data) => {
            this.setState({ slides: data })
        })



    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.slides.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.slides.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }


    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex } = this.state;
        const slides = this.state.slides.map((item, idx) => {

            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.Image}
                >
                    <img src={item.Image} className="img-fluid" alt={item.Title} />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row d-flex justify-content-start">
                                <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12 col-12">
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                </div>
                            </div>
                        </div>
                    </div>

                </CarouselItem>


            )


        });

        return (
            <div>

                <div className="header">
                    <Container>
                        <Navigacija {...this.props}></Navigacija>
                    </Container>

                    <div className="wrapper">
                        <Container fluid>
                            <Row>
                                <Carousel className="bg"
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                >
                                    {slides}
                                    <CarouselControl directionText="Previous" onClickHandler={this.previous} />
                                    <CarouselControl directionText="Next" onClickHandler={this.next} />
                                </Carousel>

                            </Row>
                        </Container>
                    </div>
                    {this.state.slides.length ?
                        <div className="previewCarousel">
                            <img src={activeIndex - 1 < 0 ? this.state.slides[this.state.slides.length - 1].Image : this.state.slides[activeIndex - 1].Image} className="img-fluid"></img>
                            <div className="aktivna">
                                <img src={activeIndex == 0 ? this.state.slides[0].Image : this.state.slides[activeIndex].Image} className="img-fluid"></img>
                            </div>
                            <img src={activeIndex + 1 >= this.state.slides.length ? this.state.slides[0].Image : this.state.slides[activeIndex + 1].Image} className="img-fluid"></img>
                        </div>
                        :
                        null}
                    {this.state.slides.length ?
                        <Container>
                            <div className="headerBox">
                                <Container>
                                    <h6>{this.state.slides[activeIndex].Subtitle[this.props.lang]}</h6>
                                    <h1>{this.state.slides[activeIndex].Title[this.props.lang]}</h1>
                                    <Link to={this.state.slides[activeIndex].ButtonLink}><Button className="btn-mehrinfo">{this.state.slides[activeIndex].ButtonText[this.props.lang]}<img src={arrowRight}></img></Button></Link>
                                </Container>
                            </div>
                            <p className="verticalText">{this.props.translate('Folgen Sie uns')}<img src={instagram} className="instagram"></img><img src={fb} className="fb"></img></p>

                        </Container>
                        : null}

                </div>

                {this.state.sections['o-nama'] ?
                    <div className="homeAbout">
                        <Container>
                            <Row>
                                <Col xl="6" lg="6" md="12" sm="12" xs="12">
                                    <Row>
                                        <img src={this.state.sections['o-nama'].Image} className="img-fluid"></img>
                                    </Row>
                                </Col>
                                <Col xl="6" lg="6" md="12" sm="12" xs="12">
                                    <h6>{this.state.sections['o-nama'].Subtitle && this.state.sections['o-nama'].Subtitle[this.props.lang]}</h6>
                                    <h1>{this.state.sections['o-nama'].Title && this.state.sections['o-nama'].Title[this.props.lang]}</h1>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.sections['o-nama'].Content && this.state.sections['o-nama'].Content[this.props.lang] }}>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                    : null
                }

                <div className="productionHome">
                    <Container>
                        <h6>{this.props.translate('WAS BIETEN WIR AN?')}</h6>
                        <h1>{this.props.translate('Produktion')}</h1>
                        <Row>
                            {
                                this.props.products.map((item, idx) => {
                                    return (
                                        <Col xl="3" lg="6" md="6" sm="12" xs="12">
                                            <Link to={`/products/${item._id}/${item.Alias}?lang=${this.props.lang}`} className="nav-link">
                                                <div>
                                                    <img className="img-fluid" src={item.HomeImage}></img>
                                                    <div className="box">
                                                        <h5>{item.Name[this.props.lang]}</h5>
                                                        <p dangerouslySetInnerHTML={{ __html: item.HomeSubtitle[this.props.lang] }}></p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>

                                    )
                                })
                            }

                        </Row>
                    </Container>
                </div>

                {this.state.sections.presentation ?
                    <div className="presentationWrapper">
                        <Container fluid>
                            <Row>
                                <Col xl="5" lg="5">
                                    <div>
                                        <img src={partner} className="img-fluid bg"></img>
                                    </div>
                                </Col>
                                <Col xl="7" lg="7">
                                    <div className="presentation2">
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <div className="presentationContent">
                            <Container>
                                <Row>
                                    <Col xl="6" lg="6" md="12" sm="!2" xs="12" onClick={() => { this.setState({ _hideVideoButton: true }) }}>
                                        {/*    <img className="img-fluid videoPicture" ></img>*/}
                                        <iframe  width="100%"  src={this.state.sections['presentation'].Video} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        {/*this.state._hideVideoButton ? <Button className="btnPlay" ><img src={play}></img></Button> : null*/}
                                    </Col>
                                    <Col xl="6" lg="6">
                                        <div className="presentationText">
                                            <h6>{this.state.sections['presentation'].Subtitle && this.state.sections['presentation'].Subtitle[this.props.lang]}</h6>
                                            <h1>{this.state.sections['presentation'].Title && this.state.sections['presentation'].Title[this.props.lang]}</h1>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.sections['presentation'].Content && this.state.sections['presentation'].Content[this.props.lang] }}>
                                            </div>

                                            <Button className="btn-mehrinfo">{this.state.sections['presentation'].ButtonText && this.state.sections['presentation'].ButtonText[this.props.lang]}<img src={arrowRight}></img></Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    : null
                }
                {this.state.sections.presentation ?

                    <div className="partner">
                        <Container>
                            <div className="partnerWrapper">

                                <Row>
                                    <Col xl={{ size: 6, order: 1 }} sm={{ size: 12, order: 1 }} xs={{ size: 12, order: 1 }}>
                                        <img src={this.state.sections['partners'].Image} className="img-fluid"></img>
                                    </Col>
                                    <Col xl={{ size: 6, order: 1 }} sm={{ size: 12, order: 0 }} xs={{ size: 12, order: 0 }}>
                                        <div className="partnerText">
                                            <h6>{this.state.sections['partners'].Subtitle && this.state.sections['partners'].Subtitle[this.props.lang]}</h6>
                                            <h1>{this.state.sections['partners'].Title && this.state.sections['partners'].Title[this.props.lang]}</h1>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.sections['partners'].Content && this.state.sections['partners'].Content[this.props.lang] }}>
                                            </div>


                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>

                    :

                    null

                }

                <div className="news">
                    <Container>
                        <Row className="d-flex justify-content-between">
                            <h6>{this.props.translate('IN BLITZ SEIN')}</h6>
                            <Link to={`/novosti?lang=${this.props.lang}`} className="nav-link">{this.props.translate('Archiv der Neuigkeiten')}</Link>

                        </Row>
                        <h1>{this.props.translate('Nachrichten')}</h1>
                        <News {...this.props}></News>
                    </Container>
                </div>

                <div className="homeGallery">
                    <Container>
                        <h6>{this.props.translate('UNSERE WERKE')}</h6>
                        <h1>{this.props.translate('Fotogalerie')}</h1>
                        <Gallery items={this.props.gallery.slice(0, 6)}></Gallery>
                        <Link to={`/galerija?lang=${this.props.lang}`} className="nav-link"><Button className="btn-mehrinfo">{this.props.translate('KOMPLETTE GALERIE')} <img src={arrowLeft}></img></Button></Link>
                    </Container>
                </div>
                <Map {...this.props}></Map>


                <Contact {...this.props}></Contact>
                <Footer {...this.props}></Footer>
            </div>
        )
    }
}