import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, NavLink as RRNavLink } from "react-router-dom";
import logo1 from '../images/logo.svg';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    NavbarBrand,
    NavItem,
    NavLink,
    DropdownMenu,
    UncontrolledDropdown,
    Navbar,
    Collapse,
    Nav, DropdownToggle
} from 'reactstrap';
import { relativeTimeThreshold } from 'moment';

export default class Navigacija extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.changeLang = this.changeLang.bind(this);


        this.state = {
            isOpen: false,
            otvoren: false,
            desktop: true
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    changeLang(lang){
        let pathname = this.props[0].location.pathname;
        let newPath;

        if (pathname.indexOf('?lang=') !== -1){
            newPath = pathname.split('?')[0] + '?lang='+lang;
        }else{
           newPath = pathname + '?lang='+lang; 
        }
        
        this.props[0].history.push(newPath);
        this.props.changeLanguage(lang);

    }

    render() {
        return (
            <div>
                <Navbar className="navbar navbar-expand-lg static-top">
                    <NavbarBrand><Link to="/">
                        <Isvg src={logo1} alt="" className="logo" />
                    </Link>
                    </NavbarBrand>
                    <button className="btn-menu" onClick={() => this.setState({ otvoren: !this.state.otvoren })}><i class="fa fa-bars"></i></button>
                    <Collapse navbar id="navbarResponsive" isOpen={this.state.otvoren}>
                        <Nav className="ml-auto" navbar>
                            <NavItem className="nav-item">
                                <NavLink exact tag={RRNavLink} className="nav-link" to={`/?lang=${this.props.lang}`} activeClassName="active">{this.props.translate('STARTSEITE')}
                                </NavLink>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret href="" >{this.props.translate('PRODUKTION')}
                                        <i className="fa fa-angle-down" ></i> </DropdownToggle>
                                <DropdownMenu right>
                                    {
                                        this.props.products.map((item, idx) => {
                                            return (
                                                <Link to={`/products/${item._id}/${item.Alias}?lang=${this.props.lang}`} className="dropdown-item">{item.Name[this.props.lang]}<i className="fa fa-angle-right" ></i></Link>

                                            )
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <Link tag={RRNavLink} to = {`/galerija?lang=${this.props.lang}`} className="nav-link" activeClassName="active">{this.props.translate('REFERENZ')}</Link>
                            </NavItem>
                            <NavItem>
                                <a tag={RRNavLink}  className="nav-link" onClick = {()=> {
                                        var asd = this.props.contactNode.offsetTop;
                                        window.scrollTo(0, asd);
                                        console.log(asd);
                                        console.log(this.props.contactNode);
                                }} activeClassName="active" >{this.props.translate('KONTAKT')}</a>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
                {this.state.otvoren ? 
                <div className="navbar2 d-flex">
                    <NavItem>
                        <a tag={RRNavLink} className="nav-link language"  activeClassName="active" onClick={() => this.changeLang('ba')}>BA</a>
                    </NavItem>
                    <NavItem>
                        <a tag={RRNavLink} className="nav-link language" activeClassName="active" onClick={() => this.changeLang('de')}>DE</a>
                    </NavItem>
                    <NavItem>
                        <a tag={RRNavLink} className="nav-link"  activeClassName="active" onClick={() => this.changeLang('en')}>EN</a>
                    </NavItem>
                </div> : null
                }

                <div className="navbar3 d-flex">
                    <NavItem>
                        <a tag={RRNavLink} className="nav-link language"  activeClassName="active" onClick={() => this.changeLang('ba')}>BA</a>
                    </NavItem>
                    <NavItem>
                        <a tag={RRNavLink} className="nav-link language"  activeClassName="active" onClick={() => this.changeLang('de')}>DE</a>
                    </NavItem>
                    <NavItem>
                        <a tag={RRNavLink} className="nav-link"  activeClassName="active" onClick={() => this.changeLang('en')}>EN</a>
                    </NavItem>
                </div> 

    
       

                <button className="btn-language" onClick={() => this.setState({ isOpen: !this.state.isOpen })}><i class="fa fa-globe"></i></button>
                <Collapse navbar id="navbarLanguage" isOpen={this.state.isOpen}>
                    <NavItem>
                        <a tag={RRNavLink} className="nav-link"  activeClassName="active"> onClick={() => this.changeLang('ba')}BA</a>
                    </NavItem>
                    <NavItem>
                        <a tag={RRNavLink} className="nav-link"  activeClassName="active" onClick={() => this.changeLang('de')}>DE</a>
                    </NavItem>
                    <NavItem>
                        <a tag={RRNavLink} className="nav-link"  activeClassName="active" onClick={() => this.changeLang('en')}>EN</a>
                    </NavItem>
                </Collapse>
            </div>
        );
    }
}
