import React from 'react';
import Navigacija from '../containers/navbar';
import { Link } from 'react-router-dom';
import News from '../containers/news.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import Contact from '../containers/contact.js';
import {
    Container,
    Row,
    Col,
    Button,
    NavItem,
    NavLink
} from 'reactstrap';

export default class Novosti extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
        }
    }
    componentDidUpdate() {
        window.scrollTo(0, 0);
    }
    componentDidMount() {
      window.scrollTo(0, 0);
      this.props.core.fetchNews({}, {time: -1}, null, (data) => {
        this.setState({ news: data.result })
    })
    
    
  }

    render() {
        return (
            <div>
                <div className="headerWrapper">
                    <div className="header">
                        <Container>
                            <Navigacija {...this.props}></Navigacija>

                            <div className="headerBreadcrumb">
                                <Row>
                                    <Col xl="9" lg="9" md="9" className="d-flex justify-content-start">
                                        <h1>{this.props.translate('NECHRICHTEN')}</h1>
                                    </Col>
                                    <Col xl="3" lg="3" md="9" className="d-flex justify-content-sm-start justify-content-xs-start justify-content-xl-end justify-content-lg-end">
                                        <div className="breadcrumbUrl">

                                            <NavItem>
                                                <Link to={`/?lang=${this.props.lang}`} className="nav-link">{this.props.translate('Startseit')}</Link>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#">{this.props.translate('Nechrichten')}</NavLink>
                                            </NavItem>
                                        </div>

                                    </Col>
                                </Row>
                            </div>

                        </Container>

                    </div>
                </div>
          
                <div className="news">
                    <Container>
                        <News {...this.props} news={this.state.news}></News>


                    </Container>
                </div>
                <Map {...this.props}></Map>
                <Contact {...this.props}></Contact>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}
