export default {  
    "de":{  
       "WAS BIETEN WIR AN?":"WAS BIETEN WIR AN?",
       "Produktion":"Produktion",
       "IN BLITZ SEIN":"IN BLITZ SEIN",
       "Archiv der Neuigkeiten":"Archiv der Neuigkeiten",
       "Nachrichten":"Nachrichten",
       "UNSERE WERKE":"UNSERE WERKE",
       "Fotogalerie":"Fotogalerie",
       "KOMPLETTE GALERIE":"KOMPLETTE GALERIE",
       "STARTSEITE":"STARTSEITE",
       "PRODUKTION":"PRODUKTION",
       "REFERENZ":"REFERENZ",
       "KONTAKT":"KONTAKT",
       "EINE ANFRAGE SENDEN":"EINE ANFRAGE SENDEN",
       "Kontaktieren sie uns":"Kontaktieren sie uns",
       "Name":"Name",
       "Nachname":"Nachname",
       "E-mail":"E-mail",
       "Ihre Nachricht":"Ihre Nachricht",
       "SENDEN":"SENDEN",
       "NAVIGATION":"NAVIGATION",
       "Startseite":"Startseite",
       "Referenz":"Referenz",
       "Kontakt":"Kontakt",
       "Folgen Sie uns":"Folgen Sie uns",
       "MEHR":"MEHR",
       "NECHRICHTEN":"NECHRICHTEN",
       "Startseit":"Startseit",
       "Nechrichten":"Nechrichten",
       "ZERTIFIKAT":"ZERTIFIKAT",
       "GALERIE":"GALERIE"
    },
    "en":{  
       "WAS BIETEN WIR AN?":"WAS BIETEN WIR AN?",
       "Produktion":"Produktion",
       "IN BLITZ SEIN":"IN BLITZ SEIN",
       "Archiv der Neuigkeiten":"Archiv der Neuigkeiten",
       "Nachrichten":"Nachrichten",
       "UNSERE WERKE":"UNSERE WERKE",
       "Fotogalerie":"Fotogalerie",
       "KOMPLETTE GALERIE":"KOMPLETTE GALERIE",
       "STARTSEITE":"STARTSEITE",
       "PRODUKTION":"PRODUKTION",
       "REFERENZ":"REFERENZ",
       "KONTAKT":"KONTAKT",
       "EINE ANFRAGE SENDEN":"EINE ANFRAGE SENDEN",
       "Kontaktieren sie uns":"Kontaktieren sie uns",
       "Name":"Name",
       "Nachname":"Nachname",
       "E-mail":"E-mail",
       "Ihre Nachricht":"Ihre Nachricht",
       "SENDEN":"SENDEN",
       "NAVIGATION":"NAVIGATION",
       "Startseite":"Startseite",
       "Referenz":"Referenz",
       "Kontakt":"Kontakt",
       "Folgen Sie uns":"Folgen Sie uns",
       "MEHR":"MEHR",
       "NECHRICHTEN":"NECHRICHTEN",
       "Startseit":"Startseit",
       "Nechrichten":"Nechrichten",
       "ZERTIFIKAT":"ZERTIFIKAT",
       "GALERIE":"GALERIE"
    },
    "ba":{  
       "WAS BIETEN WIR AN?":"WAS BIETEN WIR AN?",
       "Produktion":"Produktion",
       "IN BLITZ SEIN":"IN BLITZ SEIN",
       "Archiv der Neuigkeiten":"Archiv der Neuigkeiten",
       "Nachrichten":"Nachrichten",
       "UNSERE WERKE":"UNSERE WERKE",
       "Fotogalerie":"Fotogalerie",
       "KOMPLETTE GALERIE":"KOMPLETTE GALERIE",
       "STARTSEITE":"STARTSEITE",
       "PRODUKTION":"PRODUKTION",
       "REFERENZ":"REFERENZ",
       "KONTAKT":"KONTAKT",
       "EINE ANFRAGE SENDEN":"EINE ANFRAGE SENDEN",
       "Kontaktieren sie uns":"Kontaktieren sie uns",
       "Name":"Name",
       "Nachname":"Nachname",
       "E-mail":"E-mail",
       "Ihre Nachricht":"Ihre Nachricht",
       "SENDEN":"SENDEN",
       "NAVIGATION":"NAVIGATION",
       "Startseite":"Startseite",
       "Referenz":"Referenz",
       "Kontakt":"Kontakt",
       "Folgen Sie uns":"Folgen Sie uns",
       "MEHR":"MEHR",
       "NECHRICHTEN":"NECHRICHTEN",
       "Startseit":"Startseit",
       "Nechrichten":"Nechrichten",
       "ZERTIFIKAT":"ZERTIFIKAT",
       "GALERIE":"GALERIE"
    }
 }