import React from 'react';
import arrowLeft from '../images/lijevo.png';

import {
    Container,
    Row,
    Col,
    Form, FormGroup,
    Input,
    Button
} from 'reactstrap';

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.contact = this.contact.bind(this);

        this.state = {

        }


    }


    contact(){
        this.props.core.contact({
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            EMail: this.state.EMail,
            Message: this.state.Message
        }, (data) => {
            this.setState({
                _done: true
            })
        })
    }

    render() {
        return (
            <div>
                <div className="contact" ref={(node) => this.props.updateContactRef(node)}>
                    <Container>
                        <h6>{this.props.translate('EINE ANFRAGE SENDEN')}</h6>
                        <h1>{this.props.translate('Kontaktieren sie uns')}</h1>
                        <Form onSubmit={(e) => { e.preventDefault(); }}>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Row>
                                        <Col xl={6} lg={6} md={6}>
                                            <FormGroup>
                                                <Input value={this.state.FirstName} onChange={(e) => {this.setState({FirstName: e.target.value})}} type="text" id="name" placeholder={this.props.translate('Name')} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Input value={this.state.LastName} onChange={(e) => {this.setState({LastName: e.target.value})}} type="text" id="nachname" placeholder={this.props.translate('Nachname')} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Input value={this.state.EMail} onChange={(e) => {this.setState({EMail: e.target.value})}} type="text" id="email" placeholder={this.props.translate('E-mail')} />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={6} lg={6} md={6}>
                                            <Input value={this.state.Message} onChange={(e) => {this.setState({name: e.target.value})}} type="textarea" id="ihreNachricht" placeholder={this.props.translate('Ihre Nachricht')} >{this.props.translate('Ihre Nachricht')}</Input>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-end">
                            { !this.state._done ?
                                <Button className="btn-mehrinfo" onClick={this.contact}>{this.props.translate('SENDEN')}<img src={arrowLeft}></img></Button>
                                :
                                <Button className="btn-mehrinfo">{this.props.translate('Ihre Nachricht wurde versendet')}</Button>


                            }
                            </Row>
                        </Form>

                    </Container>
                </div>
            </div>
        );
    }
}
