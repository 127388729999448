import React from 'react';
import mapMarker from '../images/iconMarker.png';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.state = {};
        }
 
    initMap() {
        this.setState({
            _mapInit: true
        });

 
        var latLng = new window.google.maps.LatLng(44.451487, 18.6521519);


        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 16,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "gestureHandling",
        });


        var marker = new window.google.maps.Marker({
            position: latLng,
            map: map,
            icon: mapMarker
        });

    }

     componentDidMount() {
        if (this.props.mapinit && !this.state._mapInit) {
            this.initMap();
        }
  
    }

    componentDidUpdate() {
        if (this.props.mapinit && !this.state._mapInit) {
            this.initMap();
        }
    }

    render() {
        return (
            <div>
                <div className = "googleMap">
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                            {
                                this.props.mapinit ?
                                    <div className="map" ref={(input) => { this.GoogleMap = input; }}>
                                        <Container>
                                            <Row>

                                            </Row>
                                        </Container>
                                    </div>
                                    : null
                            }
                        </Col>
                    </Row>
                </Container>
                </div>
            </div>
        );
    }
}
