import React from 'react';
import ReactDOM from 'react-dom';
import arrowLeft from '../images/lijevo.png';
import Navigacija from '../containers/navbar';
import image1 from '../images/image1.png';
import { Link } from 'react-router-dom';
import kunstoffFT from '../images/kunstoffFT.png';
import profine88 from '../images/Profine88.png';
import profine70 from '../images/Profine70.png';
import profine76 from '../images/Profine76.png';
import aluminium from '../images/aluminium.png';
import silverBg from '../images/silverBg.png';
import holzfenstern from '../images/holzfenstern.png';
import News from '../containers/news.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import Contact from '../containers/contact.js';
import {
    Container,
    Row,
    Col,
    Button,
    NavItem,
    NavLink
} from 'reactstrap';

export default class Stolarija extends React.Component {
    constructor(props) {
        super(props);
        this.fetchProduct = this.fetchProduct.bind(this);
        this.subproductNodes = {};

        this.state = {
            product: {},
            subproducts: [],
            certificates: []
        };
    }



    fetchProduct() {
        this.props.core.fetchProduct({ _id: this.props[0].match.params.id }, (data) => {
            this.setState({ product: data })
            console.log(data);
        })

        this.props.core.fetchSubproducts({ Product: this.props[0].match.params.id }, (data) => {
            this.setState({ subproducts: data })
        })

        this.props.core.fetchCertificates({ Product: this.props[0].match.params.id }, (data) => {
            this.setState({ certificates: data })
        })


    }


    componentDidMount() {
        window.scrollTo(0, 0);

        this.fetchProduct();

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.fetchProduct();
        }

        if (prevProps[0].match.params.subproduct != this.props[0].match.params.subproduct) {
            if (this.subproductNodes[this.props[0].match.params.subproduct]) {
                window.scrollTo(0, this.subproductNodes[this.props[0].match.params.subproduct].offsetTop);
            }
        }

        if (!prevState.subproducts.length && this.state.subproducts.length) {
            if (this.subproductNodes[this.props[0].match.params.subproduct]) {
                window.scrollTo(0, this.subproductNodes[this.props[0].match.params.subproduct].offsetTop);
            }

        }



    }




    render() {
        let product = this.state.product;

        return (
            <div>

                <div className="headerWrapper">
                    <div className="header">
                        <Container>
                            <Navigacija {...this.props}></Navigacija>

                            <div className="headerBreadcrumb">
                                <Row>
                                    <Col xl="8" lg="8" md="9" className="d-flex justify-content-start">
                                        <h1>{product && product.Name ? product.Name[this.props.lang] : ''}</h1>
                                    </Col>
                                    <Col xl="4" lg="4" md="9" className="d-flex justify-content-sm-start justify-content-xs-start justify-content-xl-end justify-content-lg-end">
                                        <div className="breadcrumbUrl">

                                            <NavItem>
                                                <Link to={`/?lang=${this.props.lang}`} className="nav-link">{this.props.translate('Startseit')}</Link>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#">{product && product.Name ? product.Name[this.props.lang] : ''}</NavLink>
                                            </NavItem>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </Container>

                    </div>
                </div>

                <div className="background">
                    <img src={image1} className="img-fluid bg"></img>
                    <div className="kunstoff" >
                        <Container>
                            <Row>
                                <Col xl="6" lg="6" md="6">
                                    <img src={product.Image} className="img-fluid"></img>
                                </Col>
                                <Col xl="6" lg="6" md="6">
                                    <div className="textWrapper">
                                        <h1>{product && product.Title ? product.Title[this.props.lang] : ''}</h1>
                                        <h2>{product && product.Subtitle ? product.Subtitle[this.props.lang] : ''}</h2>

                                        <div dangerouslySetInnerHTML={{ __html: (product && product.Content ? product.Content[this.props.lang] : '') }}>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                {this.state.subproducts.length ?
                    <div className="certificate">
                        <Container>
                            <Row className="no-gutters">
                                {
                                    this.state.subproducts.map((item, idx) => {
                                        return (
                                            <Col xl="4" lg="4" md="4" sm="4" xs="4" key={idx}>
                                                <NavItem>
                                                    <Link to={`/products/${this.props[0].match.params.id}/${this.props[0].match.params.alias}/${item._id}?lang=${this.props.lang}`} className="nav-link" ><h2>{item.Name[this.props.lang]}</h2></Link>
                                                </NavItem>

                                            </Col>

                                        )
                                    })
                                }
                            </Row>
                        </Container>
                    </div>

                    : null

                }

                {
                    this.state.subproducts.length ?

                        this.state.subproducts.map((item, idx) => {
                            if (!(idx % 2)) {
                                return (
                                    <div className="wrapperBox" ref={(node) => { this.subproductNodes[item._id] = node }}>
                                        <div className="background">
                                            <img src={silverBg} className="img-fluid bg"></img>
                                            <div className="holzfenstern">
                                                <Container>
                                                    <Row>
                                                        <Col xl={{ size: 6, order: 0 }} lg={{ size: 6, order: 0 }} md={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }}>
                                                            <img src={item.Image} className="img-fluid"></img>
                                                        </Col>
                                                        <Col xl={{ size: 6, order: 1 }} lg={{ size: 6, order: 1 }} md={{ size: 6, order: 1 }} xs={{ size: 12, order: 0 }}>
                                                            <div className="textWrapper">
                                                                <h1>{item.Name[this.props.lang]}</h1>
                                                                <div dangerouslySetInnerHTML={{ __html: item.Content[this.props.lang] }}></div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="aluminium" ref={(node) => { this.subproductNodes[item._id] = node }} >
                                        <Container>
                                            <Row>
                                                <Col xl="6" lg="6" md="6">
                                                    <div className="textWrapper">
                                                        <h1>{item.Name[this.props.lang]}</h1>
                                                        <div dangerouslySetInnerHTML={{ __html: item.Content[this.props.lang] }}></div>
                                                    </div>
                                                </Col>
                                                <Col xl="6" lg="6" md="6">
                                                    <img src={item.Image} className="img-fluid"></img>

                                                </Col>

                                            </Row>
                                        </Container>
                                    </div>
                                )
                            }

                        })


                        :
                        null

                }

                {
                    this.state.certificates.length ?

                        <div className="certificate2">
                            <Container>
                                <Row>
                                    <Col xl="12" lg="12" md="12" sm="12">
                                        <h1>{this.props.translate('ZERTIFIKAT')}</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        this.state.certificates.map((item, idx) => {
                                            return (
                                                <Col xl="4" lg="4" md="4" sm="12" xs="12" key={idx}>
                                                    <img src={item.Image} className="img-fluid"></img>
                                                    <h2>{item.Name[this.props.lang]}</h2>
                                                    <a href={item.File}><Button className="btn-mehrinfo">{this.props.translate('MEHR')}<img src={arrowLeft}></img></Button></a>
                                                </Col>

                                            )
                                        })
                                    }

                                </Row>
                            </Container>
                        </div>

                        : null
                }

                <div className="news">
                    <Container>
                        <Row className="d-flex justify-content-between">
                            <h6>{this.props.translate('IN BLITZ SEIN')}</h6>
                            <Link to={`/novosti?lang=${this.props.lang}`} className="nav-link">{this.props.translate('Archiv der Neuigkeiten')}</Link>
                        </Row>
                        <h1>{this.props.translate('Nachrichten')}</h1>
                        <News {...this.props}></News>
                    </Container>
                </div>
                <Map {...this.props}></Map>
                <Contact {...this.props}></Contact>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}
