import React from 'react';
import arrowLeft from '../images/lijevo.png';
import news1 from '../images/news1.png';
import news2 from '../images/news2.png';
import news3 from '../images/news3.png';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';
import moment from 'moment';

export default class News extends React.Component {
    constructor(props) {
        super(props);

    }


    render() {
        return (
            <div>
                <Row>
                    {
                        this.props.news.map((item, idx) => {
                            console.log(item);
                            return (
                                <Col xl="4" lg="4" md="6" sm="12">
                                    <div className="boxWrapper">
                                        <Link to={`/novosti/${item._id}/${item.Alias}?lang=${this.props.lang}`} className="nav-link">
                                            <img src={item.Image} className="img-fluid"></img>
                                            <div className="box">
                                                <h3>{item.Title[this.props.lang]}</h3>
                                                <p>{moment.unix(item.time).format('DD.MM.YYYY.')}&nbsp;&nbsp;   |   &nbsp;&nbsp;{moment.unix(item.time).format('HH:mm')}h</p>
                                                <p className="text">{item.ShortDescription[this.props.lang]}</p>
                                                <Button className="btn-mehrinfo">{this.props.translate('MEHR')}<img src={arrowLeft}></img></Button>
                                            </div>

                                        </Link>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>

            </div>
        );
    }
}
