import React from 'react';
import Navigacija from '../containers/navbar';
import { Link } from 'react-router-dom';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import Contact from '../containers/contact.js';
import news2 from '../images/news2.png';
import news3 from '../images/news3.png';
import arrowLeft from '../images/lijevo.png';
import novostiDetalji from '../images/novostiDetalji.png';
import moment from 'moment';
import {
    Container,
    Row,
    Col,
    Button,
    NavItem,
    NavLink
} from 'reactstrap';

export default class Novostidetalji extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
        };
    }
    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);

        if (prevProps[0].location.pathname !== this.props[0].location.pathname){
            this.props.core.fetchNewsItem({_id: this.props[0].match.params.id}, (data) => {
                this.setState(data);
            })

            this.props.core.fetchNews({}, {time: -1}, 2, (data) => {
                this.setState({ news: data.result })
            })
    
    
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.core.fetchNewsItem({_id: this.props[0].match.params.id}, (data) => {
            this.setState(data);
        })

        this.props.core.fetchNews({}, {time: -1}, 2, (data) => {
            this.setState({ news: data.result })
        })
        
        
    }

    render() {
        return (
            <div>
                <div className="headerWrapper">
                    <div className="header">
                        <Container>
                            <Navigacija {...this.props}></Navigacija>

                            <div className="headerBreadcrumb">
                                <Row>
                                    <Col xl="9" lg="9" md="9" className="d-flex justify-content-start">
                                        <h1>{this.props.translate('NECHRICHTEN')}</h1>
                                    </Col>
                                    <Col xl="3" lg="3" md="9" className="d-flex justify-content-sm-start justify-content-xs-start justify-content-xl-end justify-content-lg-end">
                                        <div className="breadcrumbUrl">

                                            <NavItem>
                                                <Link to={`/?lang=${this.props.lang}`} className="nav-link">{this.props.translate('Startseit')}</Link>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#">{this.props.translate('Nechrichten')}</NavLink>
                                            </NavItem>
                                        </div>

                                    </Col>
                                </Row>
                            </div>

                        </Container>

                    </div>
                </div>

                <div className="newsDetail">
                    <Container>
                        <h5>{this.state.Title && this.state.Title[this.props.lang]}</h5>
                        <p className="paragrafBlue">{moment.unix(this.state.time).format('DD.MM.YYYY.')}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{moment.unix(this.state.time).format('HH:mm')}h</p>
                        <p>{this.state.ShortDescription && this.state.ShortDescription[this.props.lang]}</p>

                        <Row>
                            <Col xl="8" lg="8" md="12" sm="12">
                                <img src={this.state.Image} className="img-fluid main"></img>
                                <div className = "paragraf" dangerouslySetInnerHTML={{__html: this.state.Content ? this.state.Content[this.props.lang] : ''}}></div>
                         
                            </Col>
                            <Col xl="4" lg="4" md="6" sm="12">

                            {
                                
                                this.state.news.map((item, idx) => {
                                    return (
                                        <Link to={`/novosti/${item._id}/${item.Alias}?lang=${this.props.lang}`} className = "nav-link">
                                        <div className="newsWrapper">
                                            <img src={item.Image} className="img-fluid"></img>
                                            <div className="box">
                                                <h3>{item.Title[this.props.lang]}</h3>
                                                <p>{moment.unix(item.time).format('DD.MM.YYYY.')}&nbsp;&nbsp;   |   &nbsp;&nbsp;{moment.unix(item.time).format('HH:mm')}h</p>
                                                <p className="text">{item.ShortDescription[this.props.lang]}</p>
                                                <Button className="btn-mehrinfo">{this.props.translate('MEHR')}<img src={arrowLeft}></img></Button>
                                            </div>
                                        </div>
                                    </Link>
        
                                    )
                                })
                            }

                            </Col>



                        </Row>
                    </Container>
                </div>
                <Map {...this.props}></Map>
                <Contact {...this.props}></Contact>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}
