import socketIO from 'socket.io-client';

const API_KEY = '5cd02bb0e37b82778f73ee25';

export class Core {
    constructor(props) {
        this.socket = socketIO('https://socket.hugemedia.online');

    }

    fetchSections(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchSections',
            query: {}
        });

        this.socket.once('fetchSections', callback);
    }


    fetchProducts(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchProducts',
            query: {}
        });

        this.socket.once('fetchProducts', callback);
    }


    fetchProduct(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchProduct',
            query: data
        });

        this.socket.once('fetchProduct', callback);
    }

    fetchSubproducts(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchSubproducts',
            query: data
        });

        this.socket.once('fetchSubproducts', callback);
    }

    fetchCertificates(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchCertificates',
            query: data
        });

        this.socket.once('fetchCertificates', callback);
    }


    fetchNews(data, sort, limit, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchNews',
            query: data,
            sort: sort,
            limit: limit
        });

        this.socket.once('fetchNews', callback);
    }

    fetchNewsItem(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchNewsItem',
            query: data,
        });

        this.socket.once('fetchNewsItem', callback);
    }

    fetchSlides(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchSlides',
            query: {},
            sort: {time: -1}
        });

        this.socket.once('fetchSlides', callback);
    }

    fetchGallery(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchGallery',
            query: {},
        });

        this.socket.once('fetchGallery', callback);
    }

    contact(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'contact',
            query: {},
            data: data
        });

        this.socket.once('contact', callback);
    }
}