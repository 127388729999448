import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import GoogleMap from './googleMapScript';
import Home from './views/home.js';
import Stolarija from './views/stolarija.js';
import Novosti from './views/novosti.js';
import Galerija from './views/galerija.js';
import Novostidetalji from './views/novostiDetalji.js';
import './App.css';
import langs from './langs';
import { Core } from './core';


class App extends Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    this.updateContactRef = this.updateContactRef.bind(this);
    window.googleMapsCallback = this.callback;
    this.contactNode = null;
    this.changeLanguage = this.changeLanguage.bind(this);
    this.translate = this.translate.bind(this);

    this.core = new Core();
    this.state = {
      lang: 'de',
      products: [],
      news: [],
      gallery: []
    };
  }


  translate(text){

      /*if (!localStorage.translate){
         localStorage.translate = JSON.stringify({
          'de': {
   
          },'en': {
   
           },'ba' : {

           }
           
         });
       }
   
       let obj = JSON.parse(localStorage.translate);
       obj.ba[text] = text;
       obj.en[text] = text;
       obj.de[text] = text;
   
       localStorage.translate = JSON.stringify(obj);
       
       return text;*/
   
       if (langs[this.state.lang][text]) {
         return langs[this.state.lang][text];
       } else {
         return text
       }
   
    }

  callback() {
    this.setState({ mapinit: true });
  }

  updateContactRef(node) {
    if (this.state.currLocation !== window.location.href) {
      this.setState({ contactNode: node, _contactNodeUpdate: true, currLocation: window.location.href })
    }
  }


  changeLanguage(lang){
    this.setState({
      lang: lang
    })
  }

  componentDidMount() {
    this.core.fetchProducts((data) => {
      this.setState({ products: data })
    })

    this.core.fetchNews({}, { time: -1 }, 3, (data) => {
      this.setState({ news: data.result })
    })

    this.core.fetchGallery((data) => {
      this.setState({ gallery: data })
    })

    if (window.location.href.indexOf('?lang=') !== -1){
      let lang = window.location.href.split('?lang=')[1];
      this.setState({
        lang: lang
      })

    }


  }

  render() {
    return (
      <div>

        <GoogleMap API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />
        <Router>
          <Switch>
            <Route exact path="/" render={(...renderProps) => (<Home updateContactRef={this.updateContactRef} core={this.core} translate={this.translate} changeLanguage={this.changeLanguage} {...renderProps} {...this.state} ></Home>)} />

            <Route exact path="/products/:id/:alias/:subproduct" render={(...renderProps) => (<Stolarija updateContactRef={this.updateContactRef} core={this.core} translate={this.translate} changeLanguage={this.changeLanguage} {...renderProps} {...this.state} ></Stolarija>)} />
            <Route exact path="/products/:id/:alias" render={(...renderProps) => (<Stolarija updateContactRef={this.updateContactRef} core={this.core} translate={this.translate} changeLanguage={this.changeLanguage} {...renderProps} {...this.state} ></Stolarija>)} />


            <Route exact path="/stolarija" render={(...renderProps) => (<Stolarija updateContactRef={this.updateContactRef} core={this.core} translate={this.translate} changeLanguage={this.changeLanguage} {...renderProps} {...this.state} ></Stolarija>)} />
            <Route exact path="/stolarija/section1" render={(...renderProps) => (<Stolarija updateContactRef={this.updateContactRef} core={this.core} translate={this.translate} changeLanguage={this.changeLanguage} {...renderProps} {...this.state} ></Stolarija>)} />
            <Route exact path="/stolarija/section2" render={(...renderProps) => (<Stolarija updateContactRef={this.updateContactRef} core={this.core} translate={this.translate} changeLanguage={this.changeLanguage} {...renderProps} {...this.state} ></Stolarija>)} />
            <Route exact path="/stolarija/section3" render={(...renderProps) => (<Stolarija updateContactRef={this.updateContactRef} core={this.core} translate={this.translate} changeLanguage={this.changeLanguage} {...renderProps} {...this.state} ></Stolarija>)} />
            <Route exact path="/novosti" render={(...renderProps) => (<Novosti updateContactRef={this.updateContactRef} core={this.core} translate={this.translate} changeLanguage={this.changeLanguage} {...renderProps} {...this.state} ></Novosti>)} />
            <Route exact path="/novosti/:id/:alias" render={(...renderProps) => (<Novostidetalji updateContactRef={this.updateContactRef} core={this.core} translate={this.translate} changeLanguage={this.changeLanguage} {...renderProps} {...this.state} ></Novostidetalji>)} />
            <Route exact path="/galerija" render={(...renderProps) => (<Galerija updateContactRef={this.updateContactRef} core={this.core} translate={this.translate} changeLanguage={this.changeLanguage} {...renderProps} {...this.state} ></Galerija>)} />
          </Switch>
        </Router>

      </div>
    );
  }
}

export default App;
